<template>
  <div class="news_article">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic"/>
    <Article
      :articleData="articleData"
      :files="files"
    />
    <router-link to="/news/all/1" class="btn" v-show="!isPreview">
      {{$t('back')}}
    </router-link>
  </div>
</template>

<script>
import '@/assets/scss/news.scss';
import BannerKv from '@/components/BannerKv.vue';
import Article from '@/components/Article.vue';

import pic01 from '@/assets/img/index/imglink_1.jpg';

import { getNews, getNewsTags } from '@/lib/public';

export default {
  name: 'NewsArticle',
  components: {
    BannerKv,
    Article,
  },
  props: ['articleId'],
  data() {
    return {
      BannerKvData: {
        titleEn: 'NUWA NEWS',
        titleZh: this.$t('news'),
        backgroundPic: pic01,
      },
      Classification: [],
      files: [],
      articleData: {
        childArticle: null,
        data: null,
      },
      isPreview: false,
    };
  },
  methods: {
    changeArticle() {
      this.articleData = {
        childArticle: null,
        data: null,
      };
      getNews(
        this.articleId,
        (response) => {
          const elem = response.data.data;
          let name;
          this.Classification.forEach((item) => {
            if (item.uuid === elem.tag_uuid) {
              name = item.name;
            }
          });
          this.articleData = {
            data: [
              {
                type: 1,
                title: elem.title,
                tag: name,
                date: elem.publish_day,
              },
              {
                type: 10,
                title: null,
                text: elem.content,
              },
            ],
          };
          const files = [];
          if (elem.file.length > 0) {
            elem.file.forEach((item) => {
              const obj = { name: item.file_name, file: item.file };
              files.push(obj);
            });
            this.files = files;
          }
        },
      );
    },
  },
  mounted() {
    if (this.$route.name === 'newsPreview') {
      this.isPreview = true;
    }
    getNewsTags(
      (response) => {
        response.data.data.forEach((elem) => {
          const temp = {
            uuid: elem.uuid,
            name: elem.name,
          };
          this.Classification.push(temp);
        });
      },
    ).then(() => {
      this.changeArticle();
    });
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      console.log(this.articleId);
      this.changeArticle();
    },
  },
};
</script>
